:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/img-home-slider-mob-01.jpg);
	--navbarbreak: 1024px;
	--primary: #bf0603;
	--secondary: #444544;
	--green: #708D81;
	--black: #000000;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--white: #ffffff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #666666;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #052444;
	--li-blue: #daf0fb;
	--grey2: #cfcaca;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;

	/*Calendar colors*/
	--not_available: #cd7898;
	--disabled: #fff;
	--available: #fff;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
	--text_color: #000;
}